








































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import controller, { IExportParams } from '@/app/ui/controllers/ManageCourierController'
import { Utils } from '@/app/infrastructures/misc'
import Modal from '@/app/ui/components/Modal/index.vue'
import SuccessIcon from "@/app/ui/assets/success_icon_modal.vue";
import {
  EnumOptions
} from '@/app/infrastructures/misc/Constants/manageCourier'
import EditIcon from '@/app/ui/assets/edit_icon_alt2.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import TaskHistoryIcon from "@/app/ui/assets/ics_f_task_history.vue"
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Dictionary } from 'vue-router/types/router'
import { IListParams } from '@/data/infrastructures/misc/interfaces/manageCourier'

interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

interface IStyle {
  minWidth: string
  maxWidth: string
  paddingLeft: string
  paddingRight: string
}

interface ITableCell {
  value: string | number | Date | string[]
  customStyle: IStyle
}

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    PaginationNav,
    Loading,
    Modal,
    SuccessIcon,
    EditIcon,
    DataTableV2,
    TaskHistoryIcon,
    LoadingOverlay
  },
})

export default class ManageCourierPage extends Vue {
  isGuest = Utils.isGuest()
  controller = controller
  driverTypeOptions: EnumOptions = [
    { label: 'Semua', value: '' },
    { label: 'KVP', value: 'KVP' },
    { label: 'POS', value: 'POS' },
    { label: 'Truck', value: 'TRUCK' },
    { label: 'Internal', value: 'INTERNAL' },
  ]
  statusOptions: EnumOptions = [
    { label: 'Semua', value: '' },
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Banned', value: 'BANNED' },
  ]
  sortOptions: EnumOptions = [
    { label: 'A-Z', value: 'ASC' },
    { label: 'Z-A', value: 'DESC' },
  ]
  parameters: IListParams = {
    page: 1,
    perPage: 100,
    q: '',
    sortBy: this.sortOptions[0],
    driverType: this.driverTypeOptions[0],
    courierStatus: this.statusOptions[0]
  }

  headers = [
    this.headerCellMapper('NO', '100px', '0px', '0px'),
    this.headerCellMapper('Kurir ID', '100px'),
    this.headerCellMapper('Full Name', '200px'),
    this.headerCellMapper('Phone', '155px'),
    this.headerCellMapper('Jenis Kurir', '120px'),
    this.headerCellMapper('Pengiriman COD', '105px'),
    this.headerCellMapper('Plate', '130px'),
    this.headerCellMapper('POS', '200px'),
    this.headerCellMapper('Status', '100px'),
    this.headerCellMapper('', '136px')
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: 100,
        q: queries.q || '',
        sortBy:
          this.sortOptions.find((item) => item.value === queries.sortBy) ||
          this.sortOptions[0],
        driverType:
          this.driverTypeOptions.find((item) => item.value === queries.driverType) ||
          this.driverTypeOptions[0],
        courierStatus:
          this.statusOptions.find((item) => item.value === queries.courierType) ||
          this.statusOptions[0],
      }
    }
    this.fetchManageCourierList()
  }

  private onSearch = Utils.debounce((val: string): void => {
    if (val.length >= 3 || val.length === 0 ) {
      this.fetchManageCourierList(true)
    }
  }, 500)

  private fetchManageCourierList(resetPage = false): void {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getManageCourierList(this.params)
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })

    const exportParams: IExportParams = {
      q: <string>val?.q,
      driverType: <string>val?.driverType,
      courierStatus: <string>val?.courierStatus,
      sortBy: <string>val?.sortBy
    }

    controller.setExportParams(exportParams)
  }

  get params(): Record<string, string | number> {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      driverType: this.parameters.driverType.value,
      courierStatus: this.parameters.courierStatus.value
    }
  }

  get manageCourierDataTable(): ITableCell[][] {
    return controller.manageCourierData.map((manageCourier, index) => [
      this.tableCellMapper(
        index + 1 + this.parameters.perPage * (this.parameters.page - 1),
        '100px',
        '0px',
        '0px'
      ),
      this.tableCellMapper(<number>manageCourier.courierId, '100px'),
      this.tableCellMapper(<string>manageCourier.fullName, '200px'),
      this.tableCellMapper(<string>manageCourier.phoneNumber, '155px'),
      this.tableCellMapper(<string>manageCourier.courierTypeValid, '120px'),
      this.tableCellMapper(<boolean>manageCourier.courierIsCod ? 'Iya' : 'Tidak', '105px'),
      this.tableCellMapper(<string>manageCourier.vehiclePlate, '130px'),
      this.tableCellMapper(<string>manageCourier.partnerName, '200px'),
      this.tableCellMapper(<string>manageCourier.status, '100px'),
      this.tableCellMapper(<number>manageCourier.courierId, '136px'),
    ])
  }

  get successModal(): boolean {
    return controller.modalSuccessAdd;
  }

  private setSuccessModal(): void {
    controller.setModalSuccess(false)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    paddingRight?: string,
    paddingLeft?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '20px',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    paddingLeft?: string,
    paddingRight?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '24px',
      },
    }
  }
}
